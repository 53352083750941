/*
    Third Party Quotes List
*/
import "./base_quotes";

client_portal.quotes.ThirdPartyQuotesListController = class ThirdPartyQuotesListController extends client_portal.quotes.BaseQuotesListController{

    set_schedule(){
        return new Promise((resolve, reject)=>{
            if(this.quotes && this.quotes.length >= 1){
                $.each(this.quotes, (idx, quote)=>{
                    quote.idx = idx+1;
                    this.handle_quote_events(this.get_quote_wrapper(quote), quote);
                });
            }else{
                this.set_quotes_unavailable();
            }
            resolve();
            
        });
    }

    get_quote_wrapper(quote){
        let $quote_wrapper = $(`<div class="insurance-item insurance-item--hybrid js-get-quote-open-btn">
                <div class="insurance-row">
                    <div class="insurance-col-img">
                        <div class="insurance-icon">
                                <img src="${this.rater.get_company_logo(quote)}" alt="">
                        </div>
                    </div>
                    <div class="insurance-col-title d-flex justify-content-between align-items-center">
                        <div>
                            <div class="insurance-title d-none d-sm-block">${this.rater.get_company_name(quote)}</div>
                            <div class="insurance-sub-title d-none d-sm-block futura-pt-book">
                                ${this.get_company_headline(quote)}
                            </div>
                        </div>
                        <div>
                            <button class="btn btn-warning btn-lg">${__("Get Quote")}</button>
                        </div>
                    </div>
                </div>
                <div class="insurance-sub-title d-sm-none futura-pt-book">
                    ${this.get_company_headline(quote)}
                </div>
            </div>`).appendTo(this.$wrapper);
        return $quote_wrapper;
     }

     get_company_headline(quote){
        return quote.company_headline;
     }
}

/*
    Third Party Quote Modal
*/
client_portal.quotes.ThirdPartyQuoteModal = class ThirdPartyQuoteModal extends client_portal.quotes.BaseModal{


    make(){
        super.make();
        this.handle_close();
        this.handle_get_quote();
    }

    on_show(){
        this.set_carrier_headline();
        super.on_show();
    }

    set_carrier_headline(){
        this.$wrapper.find(".carrier-headline")
            .text(this.get_company_headline());
    }

    handle_get_quote(){
        $(".btn-get-quotes").on("click", (e)=>{
            e.preventDefault();
            if(this.quote && this.quote.bridging_url){
                window.location.href = this.quote.bridging_url;
            }
            return false;
        })
    }

    get_company_headline(){
        return this.quote.company_headline;
    }

    get_wrapper(){
        return $(`<div class="popup-get-quotes d-none">
                <div class="popup-close position-absolute">${this.get_close_btn()}</div>
                <div class="popup-get-quotes__logo-icon mx-auto text-center">
                    <img class="img-fluid w-100 carrier-logo" src="/assets/bond_assets/images/icons/geico-logo.svg" alt="">
                </div>
                <div class="popup-get-quotes__logo-name mx-auto text-center carrier-headline">
                    GEICO - Because Having Great Car Insurance is Always in Style
                </div>
                <div class="popup-get-quotes__text-wrap mx-auto">
                    <div class="popup-get-quotes__text-icon mx-auto text-center">
                        <img class="img-fluid w-100" src="/assets/bond_assets/images/icons/single-quotes-error-icon.svg" alt="">
                    </div>
                    <div class="popup-get-quotes__text mx-auto text-center">
                        Your information will be transferred, but we won’t be able to service your purchase if you go with this carrier.
                        We can help you find a lower rate when it comes time to renew, though. Just share your quote with us and we’ll get you on board.
                    </div>
                </div>
                <div class="popup-get-quotes__btn-wrap text-center">
                    <button class="btn btn-warning btn-xl btn-get-quotes">${__("Get Quote")}</button>
                </div>
            </div>
            <div class="overlay-popup-get-quotes d-none"></div>`).appendTo($("main"))
    }
}