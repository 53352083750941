/*
    Base Controller for Rater including(System Quotes, Third Party Quotes, and Hybird Quotes)
*/
frappe.provide("client_portal.quotes");
frappe.provide("client_portal.rater");

import "./call";
import "./calendar";
import "./ui";

import "./system_rater";
import "./third_party_rater";
import "./hybrid_rater";

import "./system_quotes";
import "./third_party_quotes";
import "./hybrid_quotes";