/*
    GUI Controller
*/
frappe.provide("client_portal.ui");

client_portal.ui.MarginController = class MarginController{
    constructor(){
    }

    setup_margin(){
        this.calcInsuranceMaxHeight();
        this.calcInsuranceMarginY();
        this.handle_window_events();
    }

    handle_window_events(){
        window.addEventListener('resize', ()=> {
            this.calcInsuranceMaxHeight();
            this.calcInsuranceMarginY();
        });
    }
    calcInsuranceMarginY() {
        const insurance = document.querySelector('.insurance-wrap');
        const simplebarPlaceholder = document.querySelector('.insurance-inner .simplebar-placeholder');
    
        let heightContent = this.getInsuranceMainHeight(),
            calcMarginY;
    
        if(!insurance || !isFinite(heightContent) || !simplebarPlaceholder) return;
    
        let insuranceHeight = insurance.clientHeight;
    
        calcMarginY = (heightContent - insuranceHeight) / 2;
    
        if(calcMarginY > 10) {
            insurance.style.marginTop = `${calcMarginY - 2}px`;
            insurance.style.marginBottom = `${calcMarginY}px`;
        } else {
            insurance.style.marginTop = '8px';
            insurance.style.marginBottom = '10px';
        }

        if(simplebarPlaceholder.clientHeight <= insurance.clientHeight && window.innerWidth > 1791) {
            insurance.style.marginTop = '40px';
            insurance.style.marginBottom = '0';
        }
    }
    calcInsuranceMaxHeight() {
        const insurance = document.querySelector('.insurance-inner'),
            insuranceItem = document.querySelector('.insurance-item');
        let heightContent = this.getInsuranceMainHeight();
    
        if(!insuranceItem || !insurance || !isFinite(heightContent)) return;
    
        let insuranceItemHeight = insuranceItem.offsetHeight;
        let insuranceItemMarginBottom = getComputedStyle(insuranceItem).marginBottom;
        let fullHeightinsuranceItem = insuranceItemHeight + parseInt(insuranceItemMarginBottom);
        let countItems;
    
        if(window.innerWidth > 991) {
            countItems = Math.floor(heightContent / fullHeightinsuranceItem - 1);
        } else {
            countItems = Math.floor(heightContent / fullHeightinsuranceItem - 0.6);
        }
    
        let maxInsuranceWrapHeight = countItems * fullHeightinsuranceItem + insuranceItemHeight / 2;
    
        if(maxInsuranceWrapHeight < 245) {
            insurance.style.maxHeight = '245px';
        } else {
            insurance.style.maxHeight = `${maxInsuranceWrapHeight}px`;
        }
    
        const simplebarPlaceholder = document.querySelector('.insurance-inner .simplebar-placeholder');
        if(!simplebarPlaceholder) return;
    
        if(simplebarPlaceholder.clientHeight <= insurance.clientHeight) {
            $('.insurance-btn-top').addClass('d-none');
            $('.insurance-btn-bottom').addClass('d-none');
        } else {
            $('.insurance-btn-top').removeClass('d-none');
            $('.insurance-btn-bottom').removeClass('d-none');
        }
     }
     getInsuranceMainHeight() {
        const h = window.innerHeight,
            headerHeight = document.querySelector('.header').offsetHeight,
            hero = document.querySelector('.hero-wrapper'),
            quotes = document.querySelector('.quotes-bottom'),
            footer = document.querySelector('.footer');
    
        if(!quotes || !footer || !hero) return;
    
        let heroHeight = hero.offsetHeight,
            quotesHeight = quotes.offsetHeight,
            footerHeight = footer.offsetHeight,
            heightContent;
    
        if(window.innerWidth > 991) {
            heightContent = h - headerHeight - heroHeight - quotesHeight - footerHeight;
        } else if(window.innerWidth > 575) {
            heightContent = h - heroHeight - quotesHeight;
        } else {
            heightContent = h - heroHeight;
        }
    
        return heightContent;
    }
}