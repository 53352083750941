/*
    Call Controller
*/

frappe.provide("client_portal.call");

client_portal.call.CallController = class CallController{

    constructor(args){
        this.rater = args.rater;
        this.make();
    }

    make(){
        this.$wrapper = this.get_wrapper();
        this.$call_wrapper = this.get_call_wrapper();
        this.$call_success_wrapper = this.get_success_call_wrapper();
        this.$new_phone_wrapper = this.get_new_phone_wrapper();
        this.$wrapper.find("select").niceSelect();
        this.setup_events();
    }

    setup_events(){
        this.$wrapper.find(".call-chat__close .js-back-quotes").on("click", (e)=>{
            return this.hide();
        });

        this.handle_call_events();
        this.handle_success_events();
        this.handle_new_phone_events();

    }
    handle_call_events(){
        this.$call_wrapper.find('input[type=radio][name=number-to-reach]').change( (e)=> {
            let val = $(e.target).val();
            if (val === 'No') {
                this.$call_wrapper.addClass("d-none");
                this.$new_phone_wrapper.removeClass("d-none");
            } else if (val === 'Yes') {
                this.place_call(this.rater.get_primary_phone_number()).then((res)=>{
                    this.$call_wrapper.addClass("d-none");
                    this.$call_success_wrapper.removeClass("d-none");   
                });
            }
        });

    }

    handle_success_events(){

    }

    handle_new_phone_events(){
        this.$new_phone_wrapper.find("input[type='submit']").on("click", (e)=>{
            let val = $(this.$new_phone_wrapper.find(".new-phone-number-input")).val();
            if(!val || !this.validate_number(val)){
                return;
            }
            this.place_call(val).then(()=>{
                this.$new_phone_wrapper.addClass("d-none");
                this.$call_success_wrapper.removeClass("d-none");
            });
            return false;
        });
    }

    validate_number(phone_no){
        return bond_assets.phonenumbers.is_phone_valid(phone_no);

    }

    place_call(val){
        if(!this.rater.allow_call()){
            return false;
        }
        return new Promise((resolve, reject)=>{
            frappe.call({
                method: "client_portal.insurances_form.rater.click_to_call",
                args:{
                    service_type: this.rater.service_type,
                    service_name: this.rater.service_name,
                    name: this.quote.name,
                    quote_id: this.quote.name,
                    customer_number: val,
                    lang: this.get_user_language(),
                    bond_number: frappe.boot.website_settings.primary_phone,
                },
                freeze: true,
                freeze_message: __("Please wait while we're connecting..."),
                callback: (res)=>{
                    resolve(res);
                }
            });
        });
    }
    get_user_language(){
        let lang = this.$call_wrapper.find("select[name='language-switch-select']").val();
        if(this.$new_phone_wrapper.is(":visible")){
            lang = this.$new_phone_wrapper.find("select[name='language-switch-select']").val();
        }
        return lang;
    }

    show(quote){
        this.quote = quote;
        this.reset_values();
        this.rater.modal_controller.hide();
        this.rater.toggle_back_button(true);
        this.rater.toggle_background_blur(false);
        this.$call_wrapper.find(".existing-phone-placeholder").html(`Is ${this.rater.get_primary_phone_number()}<br> the best number to reach you?`);
        this.$call_success_wrapper.addClass("d-none");
        this.$new_phone_wrapper.addClass("d-none");
        $("main").addClass("open-call-chat");
        this.rater.$call_back_btn.removeClass("d-none");
        this.$call_wrapper.removeClass("d-none");
        this.$wrapper.removeClass("d-none");
        
    }

    get_default_success_call_msg(){
        return "We’ll ring you now…";
    }

    hide(){
        this.rater.toggle_back_button(false);
        this.rater.toggle_background_blur(true);
        $("main").removeClass("open-call-chat")
        this.$call_success_wrapper.addClass("d-none").removeClass("d-flex");
        this.$wrapper.addClass("d-none");
        this.$call_wrapper.addClass('d-none');
        this.$new_phone_wrapper.addClass("d-none");
        this.rater.$call_back_btn.addClass("d-none");
        this.rater.modal_controller.show(this.rater.modal_controller.quote);
    }

    reset_values(){
        this.$call_wrapper.find('input[name="number-to-reach"]').prop('checked', false)
            .parent().removeClass("active");
        this.$new_phone_wrapper.find(".new-phone-number-input").val('');
        this.$call_success_wrapper.find(".call-chat__title").text(__("Great!"));
        this.$call_success_wrapper.find(".call-chat__text").text(this.get_default_success_call_msg());
    }

    get_success_call_wrapper(){
        return $(`<div class="call-chat__body call-chat__body--call display-3 d-none align-items-start justify-content-center call-success-wrapper">
            <div class="call-chat__body-inner">
                <div class="call-chat__img-top text-center">
                    <img src="/assets/bond_assets/images/call-great-picture.svg" alt="">
                </div>
                <p class="m-0 call-chat__title text-center">${__("Great!")}</p>
                <p class="m-0 call-chat__text text-center mx-auto">${__("We’ll ring you now…")}</p>
            </div>
            <div class="quotes-bottom text-center d-sm-none">
                <div class="quotes-bottom-inner d-flex justify-content-between justify-content-sm-center mx-auto">
                    <div class="font-landing quotes-item-wrap">
                            <img src="/assets/bond_assets/images/icons/no-fees-icon.svg" alt="">
                                <span>${__("Friendly unbiased agents")}</span>
                    </div>
                    <div class="font-landing quotes-item-wrap"><img
                            src="/assets/bond_assets/images/icons/friendly-icon.svg" alt="">
                        <span>${__("No fees")}</span>
                    </div>
                    <div class="font-landing quotes-item-wrap">
                        <img src="/assets/bond_assets/images/icons/buy-policy-icon.svg" alt="">
                        <span>${__("Buy a policy in minutes")}</span>
                    </div>
                </div>
            </div>
        </div>`).appendTo(this.$wrapper);
    }

    get_new_phone_wrapper(){
        return $(`<div class="call-chat__body call-chat__body--call align-items-center display-2 d-none new-phone-wrapper">
            <div class="call-chat__body-inner">
                <p class="call-chat__title text-center">
                    ${__("Enter the number you’d like")}<br>
                    ${__("us to use.")}
                <form action="#">
                    <div class="col-10 mx-auto mt-4 mt-sm-3">
                        <div class="input-group input-group-lg input-group-lg-control border border-dark mx-auto w-100">
                            <input type="number"
                                class="js-text-form js-text-not-empty-tab text-center form-control border-0 pr-0 new-phone-number-input"
                                aria-describedby="inputGroup-sizing-lg-first-name"
                                placeholder="${__("Phone Number")}">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="inputGroup-sizing-lg-first-name">
                                    <span class="circle-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12"
                                            height="12" viewBox="0 0 12 12"><g><g><path
                                            fill="currentColor"
                                            d="M9.256.036a2.833 2.833 0 0 0-2.013.833L2.08 6.036a4.679 4.679 0 0 0-1.381 3.33v2.075h2.076a4.679 4.679 0 0 0 3.33-1.381l5.167-5.162A2.85 2.85 0 0 0 9.256.036z"/></g></g>
                                        </svg>
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="call-chat__submit-wrap col-10 mx-auto text-center">
                        <input id="submit-number" type="submit" name="enter-number"
                            class="btn btn-xl btn-warning text-center px-0" value=${__("Submit")}>
                    </div>
                </form>
                <div class="language-switch d-flex justify-content-between align-items-center mx-auto">
                    <div class="language-switch__title">${__("Language Preference")}</div>
                    <div class="language-switch__select-wrap">
                        <select name="language-switch-select"
                                class="nice-select-language-switch">
                            <option selected value="en">${__("English")}</option>
                            <option value="es">${__("Spanish")}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>`).appendTo(this.$wrapper);
    }

    get_call_wrapper(){
        return $(`<div class="call-chat__body call-chat__body--call align-items-start display-first call-wrapper">
            <div class="call-chat__body-inner">
                <div class="text-center call-chat__img-top">
                    <img src="/assets/bond_assets/images/call-great-picture.svg" alt="">
                </div>
                <p class="call-chat__title text-center existing-phone-placeholder"></p>
                <div class="call-chat__btn-wrap">
                    <div class="btn-group-toggle btn-group-lg d-flex justify-content-center w-100" data-toggle="buttons">
                        <label class="d-flex justify-content-between align-items-center btn btn-outline-warning mr-2 mr-sm-3 w-auto">
                            <input type="radio" name="number-to-reach" value="Yes">
                            <span class="label-placeholder">${__("Yes")}</span>
                            <span class="circle-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g><g><path fill="currentColor"
                                    d="M10.848 8.608l.472 2.752a.953.953 0 0 1-1.383 1l-2.472-1.3a.947.947 0 0 0-.887 0l-2.47 1.3a.952.952 0 0 1-1.384-1l.472-2.752a.955.955 0 0 0-.275-.844l-2-1.948a.953.953 0 0 1 .527-1.627l2.763-.4a.958.958 0 0 0 .718-.522l1.235-2.5a.954.954 0 0 1 1.71 0l1.236 2.5a.95.95 0 0 0 .717.522l2.763.4a.955.955 0 0 1 .53 1.627l-2 1.948a.95.95 0 0 0-.274.844"/></g></g>
                                </svg>
                            </span>
                        </label>
                        <label class="d-flex justify-content-between align-items-center btn btn-outline-warning w-auto">
                            <input type="radio" name="number-to-reach" value="No">
                            <span class="label-placeholder">${__("No")}</span>
                            <span class="circle-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g><g><path fill="currentColor"
                                    d="M10.848 8.608l.472 2.752a.953.953 0 0 1-1.383 1l-2.472-1.3a.947.947 0 0 0-.887 0l-2.47 1.3a.952.952 0 0 1-1.384-1l.472-2.752a.955.955 0 0 0-.275-.844l-2-1.948a.953.953 0 0 1 .527-1.627l2.763-.4a.958.958 0 0 0 .718-.522l1.235-2.5a.954.954 0 0 1 1.71 0l1.236 2.5a.95.95 0 0 0 .717.522l2.763.4a.955.955 0 0 1 .53 1.627l-2 1.948a.95.95 0 0 0-.274.844"/></g></g>
                                </svg>
                            </span>
                        </label>
                    </div>
                </div>
                <div class="language-switch d-flex justify-content-between align-items-center mx-auto">
                    <div class="language-switch__title">${__("Language Preference")}</div>
                    <div class="language-switch__select-wrap">
                        <select name="language-switch-select" class="nice-select-language-switch">
                            <option selected value="en">${__("English")}</option>
                            <option value="es">${__("Spanish")}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>`).appendTo(this.$wrapper);
    }

    get_wrapper(){
        return $(`<div class="call-chat js-call-content d-none call-main-wrapper">
            <div class="call-chat__header d-sm-flex justify-content-between align-items-center bg-dark">
                <div class="js-chat-header">${__("Connect Now")}</div>
                <div class="call-chat__close">
                    <button class="btn js-back-quotes">
                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="11"
                            viewBox="0 0 10 11">
                            <g>
                                <g>
                                    <g>
                                        <path fill="none" stroke="#fff" stroke-linecap="round"
                                            stroke-miterlimit="20" stroke-width="1.5"
                                            d="M1 1.143v0l6.657 7.196v0L9.193 10v0"/>
                                    </g>
                                    <g transform="rotate(91 5.095 5.57)">
                                        <path fill="none" stroke="#fff" stroke-linecap="round"
                                            stroke-miterlimit="20" stroke-width="1.5"
                                            d="M.74 1.55v0l7.079 6.535v0l1.634 1.508v0"/>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </button>
                </div>
            </div>
        </div>`).appendTo($("main"));
    }
}