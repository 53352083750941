/*
    Quote Controller
*/
frappe.provide("client_portal.quotes");

client_portal.quotes.BaseQuotesListController = class QuotesListController{
    constructor(args){
        this.quotes = []
        this.data = args.data;
        if(args.data && args.data.quotes){
            this.quotes = args.data.quotes;
        }
        if(args.data && args.data.system_quotes){
            this.system_quotes = this.data.system_quotes;
        }
        if(args.data && args.data.third_party_quotes){
            this.third_party_quotes = this.data.third_party_quotes;
        }
        this.rater = args.rater;
        this.make();
    }

    make(){
        this.$wrapper = $(".quotes-wrapper");
        this.set_schedule().then(()=>{
            this.set_tooltip();
            this.setup_simple_bar();
            frappe.dom.unfreeze();
        });
    }

    handle_quote_events($quote, quote){
        return $quote.on("click", (e)=>{
            return this.rater.modal_controller.show(quote);
        });
    }

    setup_simple_bar(){
        if(this.quotes && this.quotes.length){
            this.$scroll_top_btn  = this.rater.$main.find(".insurance-btn-top").removeClass("d-none");
            this.$scroll_bottom_btn = this.rater.$main.find(".insurance-btn-bottom").removeClass("d-none");

            let timer1 = null, timer2 = null;
            function scrollDown() {
                let content = $('.simplebar-content-wrapper');
                content.scrollTop(content.scrollTop() + 5);
            }
            
            function scrollUp() {
                let content = $('.simplebar-content-wrapper');
                content.scrollTop(content.scrollTop() - 5);
            }

            // Scroll bottom
            this.$scroll_bottom_btn.bind('mousedown touchstart', function () {
                timer1 = setInterval(scrollDown, 25);
            });

            this.$scroll_bottom_btn.bind('mouseup touchend', function () {
                clearInterval(timer1);
            });
            
            // scroll top
            this.$scroll_top_btn.bind('mouseup touchend', function () {
                timer2 = setInterval(scrollUp, 25);
            });
            
            this.$scroll_top_btn.bind('mouseup touchend', function () {
                clearInterval(timer2);
            });

            this.quote_scroll_controller = new SimpleBar(this.$wrapper.get(0), { autoHide: false, forceVisible: true });
            this.rater.setup_margin();
        }
    }

    get_quote_wrapper(quote){
        let $quote_wrapper;
    
        $quote_wrapper = $(`<div class="insurance-item js-popup-open-btn">
                    <div class="insurance-row">
                        <div>
                            <div class="insurance-icon">
                                <img src="${this.rater.get_company_logo(quote)}" alt="">
                            </div>
                        </div>
                        <div class="insurance-col-title d-flex justify-content-between align-items-center">
                            <div>
                                <div class="insurance-title">${this.rater.get_company_name(quote)}</div>
                                <div class="insurance-quote-id d-none d-sm-block futura-pt-book"></div>
                            </div>
                            <div class="insurance-price-wrap js-price-tooltip">
                            </div>
                        </div>
                    </div>
                </div>`);
        $quote_wrapper.appendTo(this.$wrapper);
        $quote_wrapper.find(".insurance-quote-id").html(`Quote ID: <span>${this.rater.get_unique_quote_code(quote)}</span>`);
        if(quote && quote.total){
            $quote_wrapper.find(".insurance-price-wrap").html(`${this.rater.get_installment_payment_label(quote)}<span class="font-landing futura-pt-book">/mo</span>`);
        }else{
            //
            $quote_wrapper
            .find(".insurance-price-wrap")
            .addClass("error")
            .html(`<span class="error">...</span><span class="font-landing futura-pt-book">/mo</span>`);
        }

        return $quote_wrapper;

     }

     set_quotes_unavailable(){
        $(".insurance-btn-top").addClass("d-none");
        $(".insurance-btn-bottom").addClass("d-none");
     }

     set_tooltip(){
            //tooltip for price
        let options = {
            placement: 'top',
            trigger: 'mouseenter focus',
            theme: 'custom-black',
            arrow: false,
            allowHTML: true,
            maxWidth: 370,
            offset: [-6, 3],
            zIndex: 1,
            interactive: false,
        };
        if(window.innerWidth > 991){
            tippy_utils.set_tooltip("js-price-tooltip", "quote-list-price-tooltip", options);
        }
     }
}

/*
    Base System Quote Modal
*/
client_portal.quotes.BaseModal = class BaseModal{

    constructor(args){
        this.rater = args.rater;
        this.data = args.data;
        this.make();
    }

    make(){
        this.$wrapper = this.get_wrapper();
        if(!this.rater.allow_call() || !company_schedule.utils.is_available()){
            this.$wrapper.find(".js-btn-call").addClass("d-none");
            this.$wrapper.find(".js-btn-call").hide();
            this.$wrapper
                .find(".single-quotes__desc")
                .text(`If you'd like to purchase a policy, use the Schedule Call button above and one of our licensed insurance experts will reach out to you at the day and time you choose.`);
        }
    }

    show(quote){
        this.quote = quote;
        this.$wrapper.removeClass("d-none");
        this.$wrapper.find(".single-quote-schedule").empty().addClass("d-none");
        this.$wrapper.find(".quotes-error").addClass("d-none");
        this.set_schedule();
        this.on_show();
    }

    on_show(){
        this.$wrapper.find(".carrier-logo").attr("src", this.rater.get_company_logo(this.quote, true));
        this.setup_tooltip_info();
        let quote_id = this.rater.get_unique_quote_code(this.quote);
        if(quote_id){
            this.$wrapper.find(".single-quotes__id").html(`Quote ID: <span>${quote_id}</span>`);
        }else{
            this.$wrapper.find(".single-quotes__id").html();
        }
        this.$wrapper.removeClass("d-none");
        this.rater.toggle_background_blur(false);
        this.rater.toggle_back_button(true);
    }

    hide(){
        this.$wrapper.find(".popup-close-btn").click();
    }

    on_hide(){
        this.$wrapper.addClass("d-none");
        this.rater.toggle_background_blur(true);
        this.rater.toggle_back_button(false);
     }
 

    set_schedule(){
        let installments = this.rater.get_installments(this.quote);
        if(!installments.length){
            this.set_quote_unavailable_msg();
        }else{
            $.each(installments, (idx, payment_info)=>{
                if(payment_info.is_total){
                    $(`<div class="payment-card payment-card--total">
                            <div class="payment-card__num">${payment_info.payment_label}</div>
                            <div class="payment-card__desc">${payment_info.month}</div>
                    </div>`).appendTo(this.$wrapper.find(".single-quote-schedule"));
                }else{
                    $(`<div class="payment-card">
                            <div class="payment-card__num">${payment_info.payment_label}</div>
                            <div class="payment-card__desc">${payment_info.month}</div>
                    </div>`).appendTo(this.$wrapper.find(".single-quote-schedule"));
                }
                if(idx == 0){
                    $(`<div class="single-quotes__math-symbol">+</div>`).appendTo(this.$wrapper.find(".single-quote-schedule"));
                }else if(idx == 1){
                    $(`<div class="single-quotes__math-symbol">=</div>`).appendTo(this.$wrapper.find(".single-quote-schedule"));
                }
            });
            this.$wrapper.find(".single-quote-schedule").removeClass("d-none");
        }
    }

    handle_call(){
        this.$wrapper.find(".js-btn-call").on("click", ()=>{
            this.handle_call_event();
            return false;
        });
    }

    handle_chat(){
        this.$wrapper.find(".js-btn-chat").on("click", ()=>{
            //console.log("Chat button clicked");
            return false;
        });

    }

    handle_schedule_call(){
        this.$wrapper.find(".js-btn-after-hour-v2").on("click", (e)=>{
            e.preventDefault();
            this.hide();
            this.rater.after_hour_controller.show(this.quote, true);
            return false;
        });
    }

    handle_close(){
        this.$wrapper.find(".popup-close-btn").on("click", ()=>{
            this.on_hide();
            return false;
        });
    }

    setup_tooltip_info(){
        let tippyTriger = window.innerWidth > 991 ? 'mouseenter focus' : 'click';
        let tippyMaxWidth = window.innerWidth > 575 ? 396 : 386;
        let tippyOffset = window.innerWidth > 575 ? [116, 5] : [108, 5];
        this.$wrapper.find(".coverage-title").text(this.rater.get_single_quote_coverage_title(this.quote));
        
        tippy_utils.set_tooltip('js-basic-coverage', "tmp-basic-coverage-tooltip", {
            content: this.rater.get_single_quote_coverage_tooltip(this.quote),
            placement: 'bottom',
            trigger: tippyTriger,
            theme: 'custom-single-quotes',
            arrow: false,
            allowHTML: true,
            maxWidth: tippyMaxWidth,
            offset: tippyOffset,
        });
    }

    set_quote_unavailable_msg(){
        this.$wrapper.find(".quotes-error").removeClass("d-none");
    }

    handle_call_event(){
        if(!company_schedule.utils.is_still_available()){
            //this.hide();
            this.rater.after_hour_controller.show(this.quote, false);
        }else{
            this.rater.call_controller.show(this.quote)
        }
    }

    get_close_btn(){
        return `<button id="popup-close-btn" class="btn popup-close-btn">
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="11"
                viewBox="0 0 10 11">
                <g>
                    <g>
                        <g>
                            <path fill="none" stroke="#161617" stroke-linecap="round"
                                stroke-miterlimit="20" stroke-width="1.5"
                                d="M1 1.143v0l6.657 7.196v0L9.193 10v0"></path>
                        </g>
                        <g transform="rotate(91 5.095 5.57)">
                            <path fill="none" stroke="#161617" stroke-linecap="round"
                                stroke-miterlimit="20" stroke-width="1.5"
                                d="M.74 1.55v0l7.079 6.535v0l1.634 1.508v0"></path>
                        </g>
                    </g>
                </g>
            </svg>
        </button>`
    }

    validate_call_btn(){
        return this.rater.allow_call();
    }
};