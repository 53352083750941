/*
    Navbar Settings controller
*/
frappe.provide("client_portal.navbar");

client_portal.navbar.Settings = class Settings{

    constructor(rater){
        this.rater = rater;
        this.make();
    }

    make(){
        if(bond_assets.device.is_mobile_device()){
            $(".nav-mobile-btn-mobile-view").addClass("d-none");
            this.$nav_wrapper = $(`<div class="d-flex justify-content-center pr-3">
                <button class="d-none js-btn-after-hour btn btn-warning btn-after-hour-mob d-sm-none mr-3 book-appointment">
                    ${__("Schedule Call")}
                </button>
                <button type="button" class="d-none d-sm-block btn btn-warning js-btn-chat js-popup-open-btn js-chat-tooltip chat-icon"></button>
                <button type="button" class="d-sm-none btn btn-warning js-btn-mob-call call-icon make-call-slider"></button>
                <button type="button" class="d-none d-sm-block btn btn-warning js-btn-call js-popup-open-btn js-call-tooltip call-icon"></button>
            </div>`).appendTo(".mobile-header-wrapper");
            this.setup_mobile_events();
        }else{
            this.$nav_wrapper = $(".nav-mobile-btn").html(`<li class="pr-3">
                <div class="hidden-mob d-flex justify-content-center active">
                    <button type="button" class="btn btn-warning js-popup-open-btn mr-3 js-btn-after-hour book-appointment d-none">
                        ${__("Schedule Call")}
                    </button>
                    <button type="button" class="btn btn-warning js-btn-chat js-popup-open-btn js-chat-tooltip chat-icon d-none open-chat"></button>
                    <button type="button" class="btn btn-warning js-btn-call js-popup-open-btn js-call-tooltip call-icon make-call"></button>
                </div>
            </li>`);
            this.setup_desktop_events();
        }
    }

    setup_mobile_events(){
        $(".book-appointment-mobile-btn").on("click", (e)=>{
            e.preventDefault();
            this.$nav_wrapper.find(".make-call-slider").click();
            this.rater.validate_and_show_view(true);
            return false;
        });

        this.$nav_wrapper.find(".book-appointment").on("click", (e)=>{
            e.preventDefault();
            this.rater.validate_and_show_view(true);
            return false;
        });

        $(".make-call-mobile-btn").on("click", (e)=>{
            e.preventDefault();
            if(this.rater.allow_call()){
                this.$nav_wrapper.find(".make-call-slider").click();
                this.rater.validate_and_make_call();
            }
            return false;
        });

        // to toggle the mobile slider
        this.$nav_wrapper.find(".make-call-slider").on("click", (e)=>{
            e.preventDefault();
            $(e.target).toggleClass("active");
            $(".chat-mob").toggleClass("active");
            return false;
        });
    }

    setup_desktop_events(){

        this.$nav_wrapper.find(".book-appointment").on("click", (e)=>{
            e.preventDefault();
            this.rater.validate_and_show_view(true);
            return false;
        });

        this.$nav_wrapper.find(".make-call").on("click", (e)=>{
            e.preventDefault();
            if(this.rater.allow_call()){
                this.rater.validate_and_make_call();
            }
            return false;
        });

        this.$nav_wrapper.find(".open-chat").on("click", (e)=>{
            e.preventDefault();
            /*
                Future functionality for Slack
            */
            return false;
        });
    }
}