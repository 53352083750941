/*
    Base Controller
*/
frappe.provide("client_portal.rater");

import "./base_navbar";

client_portal.rater.BaseRater = class BaseRater{
    constructor(){
       this.set_default_values();
       this.setup_navigation_bar();
       this.make();
    }

    show_confirmation_message(){
        if(this.searchParams.get("is_manual") == "1"){
            return;
        }
        this.$confirmation_wrapper = $(`<div class="popup popup-go-it position-fixed text-center" style="z-index:1500;">
            <div class="popup-go-it__text-top futura-pt-book">
                The current economic climate is resulting in a more
                conservative approach to writing policies and many carriers have stopped providing
                instant quotes, as they would have done in the past.
            </div>

            <div class="popup-go-it__icon">
                <img src="/assets/bond_assets/images/icons/go-it-quotes-icon.svg" alt="" />
            </div>

            <h3 class="popup-go-it__caption">Not to worry.</h3>

            <div class="popup-go-it__text-bottom">
                We are manually obtaining your quotes and
                will be reaching out shortly with all of your options.
            </div>
            <div class="popup-go-it__btn-wrap ">
                <button class="btn btn-xl btn-warning popup-close-btn btn-got-it">Got it</button>
            </div>
        </div>`).appendTo(this.$main.find(".bg-quotes-gradient-wrapper"));

        this.toggle_background_blur(false);
        this.$confirmation_wrapper.find(".btn-got-it").on("click", (e)=>{
            this.$confirmation_wrapper.addClass("d-none");
            this.toggle_background_blur(true);
            return false;
        });
    }

    setup_navigation_bar(){
        this.navbar_settings = new client_portal.navbar.Settings(this);
    }

    set_default_values(){
        this.$parent = $("main");
        this.$main = this.$parent;
        this.searchParams = new URLSearchParams(window.location.search);
        this.service_type = this.searchParams.get("service_type");
        this.service_name = this.searchParams.get("service_name");
        this.appointment = this.searchParams.get("appointment");
        this.view = cstr(this.searchParams.get("view")).toLowerCase();
        this.setup_blur();
    }

    set_background_image(){
        let background_img = "";
        if(this.service_type == "Personal Auto"){
            background_img = "url('/assets/bond_assets/images/backgrounds/form-quotes-desktop.svg')";
        }else if(this.service_type == "Homeowners"){
            background_img = "url('/assets/bond_assets/images/backgrounds/form-quotes-desktop.svg')";
        }
        this.$main
            .css("background-image", background_img)
            .css("background-size", "cover")
            .css("height", "1px");
    }

    make(){
        if(bond_assets.device.is_mobile_device()){
            this.$parent.find(".video-bg").removeClass("d-none");
            $(".main-heading").html("Your quotes are about to be displayed.");
        }else{
            this.set_background_image();
        }
        this.setup_back_button();
        this.setup_call_close_button();
        this.setup_blur();
        this.setup_price_tooltip();
        this.get_quotes().then((res)=>{
            this.data = res.message;
            this.set_hero_titles();
            this.setup_controllers();
            this.rater.validate_and_show_view();
            if(!this.allow_call() || !company_schedule.utils.is_available()){
                this.disable_calling();
            }
        });
    }

    get_quotes(){
        return new Promise((resolve, reject)=>{
            let params = {
                service_type: this.searchParams.get("service_type"),
                service_name: this.searchParams.get("service_name"),
                is_hybrid: 0,
                is_third_party: 0
            }
            if(this.is_hybrid){
                params.is_hybrid = 1;
            }else if(this.is_third_party){
                params.is_third_party = 1;
            }
            let args = {
                method: "client_portal.insurances_form.rater.get_quotes",
                args: params,
                callback: (res)=>{
                    this.$parent.find(".video-bg").addClass("d-none");
                    this.set_background_image();
                    resolve(res);
                },
                error: ()=>{
                    reject(res);
                }
            }
            if(!bond_assets.device.is_mobile_device()){
                args.freeze = true;
                args.freeze_message = "Your quotes are about to be displayed.";
            }
            frappe.call(args);
        });
    }

    validate_and_show_view(from_event){
        if((this.view === "calendar" || from_event) && this.quote_list_controller && this.quote_list_controller.quotes){
		    this.after_hour_controller.show(this.quote_list_controller.quotes[0]);
        }
    }
    
    validate_and_make_call(){
        if(this.quote_list_controller && this.quote_list_controller.quotes){
            this.modal_controller.show(this.quote_list_controller.quotes[0]);
		    this.call_controller.show(this.quote_list_controller.quotes[0]);
        }
    }

    send_for_quotes(){
        frappe.xcall("client_portal.insurances_form.rater.send_for_quotes",{
            service_type: this.service_type,
            service_name: this.service_name,
        }).then((res)=>{

        });
    }
    setup_controllers(){
        /*
            add controller in sales
         */
    }

    handle_modal_events(){
        let args = $.extend({}, {
            service_type: this.service_type,
            service_name: this.service_name,
            customer_number: this.data.lead.mobile_no,
        });
        portal.utils.quotes.display_mobile_confirmation_dialog(args);
    }

    get_unique_quote_code(quote){
        let code = '';
        if(quote.raw_data && quote.raw_data.PhoneCode){
            code = quote.raw_data.PhoneCode;
        }
        return code;
    }

    get_total_premium_label(quote){
        return format_currency(quote.total, "$", 0);
    }

    get_installment_payment_label(quote){
        return format_currency(quote.pay_plan_payment_amount, "$", 0);
    }
    
    get_down_payment_label(quote){
        return format_currency(quote.pay_plan_down_payment, '$', 0);
    }

    get_company_logo(quote, medium=false){
        let logo = quote.logo;
        if(this.is_hybrid){
            logo = quote.hybrid_page_logo;
        }
        if(medium){
            logo = quote.medium_logo;
        }
        logo = cstr(logo);
        if(logo.indexOf("http") >= 0){
            return logo;
        }
        return `${frappe.boot.internal_erp}/${logo}`;
    }

    get_hybrid_hover_over_icon(quote){
        let logo = cstr(quote.hybrid_page_logo_hover_over);
        if(logo.indexOf("http") >= 0){
            return logo;
        }
        return `${frappe.boot.internal_erp}/${logo}`;
    }

    get_company_name(quote){
        return quote.company_name;
    }
   
    get_installments(quote){
        let installments = [];
        if(!quote.total){
            return installments;
        }
       
        installments = [{
            month: __("First Month Payment"),
            payment_label: this.get_down_payment_label(quote).replace(" ", "")
        },{
            month: __(cstr(quote.term -1 )+" Monthly Payments"),
            payment_label: this.get_installment_payment_label(quote).replace(" ", "")
        },{
            month: __(`Total ${quote.term} Month Premium`),
            payment_label: this.get_total_premium_label(quote).replace(" ", ""),
            is_total: true
        }];

        return installments;
    }


    get_main_heading(){
        let title = "";
        if(this.service_type === "Personal Auto"){
            title = this.get_personal_auto_heading();
        }else if(in_list(["Homeowners", "Condo", "Renters"], this.service_type)){
            title = this.get_homeowners_heading();
        }
        return title;
    }

    get_personal_auto_heading(){
        let title = __("<span class='bold'>{0} {1}</span>", [this.data.lead.first_name, this.data.lead.last_name])
        let opportunity = this.data.opportunity;
        if(opportunity && opportunity.vehicles && opportunity.vehicles.length === 1){
            let vehicle = opportunity.vehicles[0];
            title = __("{0} | {1} {2} {3}", [title, "Quotes for your", vehicle.year, vehicle.make]);
        }else if(opportunity && opportunity.vehicles && opportunity.vehicles.length >= 2){
            title = __("{0} | {1}", [title, "Quotes for your Vehicles."]);
        }
        return title;
    }

    get_homeowners_heading(){
        let service_title = "home";
        if(this.service_type == "Condo"){
            service_title = "condo";
        }else if(this.service_type == "Renters"){
            service_title = "apartment"
        }
        let title = __("<span class='bold'>{0} {1}</span>", [this.data.lead.first_name, this.data.lead.last_name]);
        title = __("{0} | {1} {2} at {3}", [title, "Quotes for your", service_title, this.data.lead.home_address_line1]);
        return title;
    }

    get_primary_phone_number(){
        return this.data.lead.mobile_no;
    }

    get_data(){
        return this.data;
    }

    toggle_background_blur(toggle){
        $('.bg-overlay').toggleClass('d-none', toggle);

    }

    toggle_back_button(toggle){
        //$('.back-quotes-btn--fixed').toggleClass('d-sm-flex', toggle);
    }

    
    setup_back_button(){
        this.$back_button_wrapper = $(` <button class="back-quotes-btn back-quotes-btn--fixed js-back-quotes d-none btn align-items-center justify-content-center mx-auto p-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="15.541" height="11.039" viewBox="0 0 15.541 11.039">
                <g id="arrow" transform="translate(2074.035 1089.548) rotate(180)">
                    <g id="Сгруппировать_11542" data-name="Сгруппировать 11542" transform="translate(2059.344 1079.711)">
                        <line id="Линия_209" data-name="Линия 209" x2="13.558" transform="translate(0 4.398)" fill="none" stroke="#161617" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.7"/>
                        <path id="Контур_27836" data-name="Контур 27836" d="M2072.273,1083.088l4.317,4.317-4.317,4.318" transform="translate(-2062.749 -1083.088)" fill="none" stroke="#161617" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.7"/>
                    </g>
                </g>
            </svg>
            ${__("Back to Quotes")}
        </button>`).appendTo(this.$main);

        this.$back_button_wrapper.on("click", (e)=>{
            this.go_back();
        });
    }

    setup_call_close_button(){
        this.$call_back_btn = $(`<div class="call-chat-mob-header d-none">
            <div class="call-chat__header d-sm-flex justify-content-between align-items-center bg-dark">
                <div class="js-chat-header">${__("Connect Now")}</div>
                <div class="call-chat__close">
                    <button class="btn js-back-quotes">
                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11"> <g> <g> <g> <path fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="20" stroke-width="1.5" d="M1 1.143v0l6.657 7.196v0L9.193 10v0"></path> </g> <g transform="rotate(91 5.095 5.57)"> <path fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="20" stroke-width="1.5" d="M.74 1.55v0l7.079 6.535v0l1.634 1.508v0"></path> </g> </g> </g>
                        </svg>
                    </button>
                </div>
            </div>
        </div>`).appendTo(this.$main);

        this.$call_back_btn.find(".js-back-quotes").on("click", (e)=>{
            e.preventDefault();
            this.go_back();
            return false;
        });
    }

    go_back(){
        if(this.call_controller.$wrapper.is(":visible")){
            this.call_controller.hide();
        }else if(this.modal_controller.$wrapper.is(":visible")){
            this.modal_controller.hide();
        }
    }

    setup_blur(){
        this.$blur_wrapper = $(`<div class="bg-overlay position-absolute h-100 w-100 d-none"></div>`).appendTo(this.$main);
    }

    setup_price_tooltip(){
        this.$price_toolip_wrapper = $(`<div class="tmp-price-tooltip d-none"  id="quote-list-price-tooltip">
            <div class="futura-pt-book">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                    viewBox="0 0 20 20">
                    <g>
                        <g>
                            <path fill="#f9f9f9"
                                d="M12.75 14.536a.98.98 0 0 1-.205.348c-.533.553-1.19.881-1.968.881-.369 0-.717 0-1.086-.061-.595-.082-1.353-.82-1.25-1.599l.245-1.599c.164-.922.328-1.865.492-2.787 0-.061.02-.123.02-.184 0-.39-.122-.533-.512-.574-.164-.02-.328-.041-.492-.082-.184-.062-.286-.226-.266-.37.02-.163.123-.266.328-.307.102-.02.225-.02.348-.02h2.829c.348 0 .553.164.553.512 0 .287-.04.574-.102.861-.185 1.066-.39 2.111-.574 3.177-.062.348-.144.697-.185 1.045-.02.164 0 .349.041.513.062.225.226.348.451.328.185-.021.37-.082.554-.164.143-.062.266-.164.41-.205.246-.082.43.061.369.287zM10.638 4.513c.82 0 1.476.676 1.476 1.496s-.676 1.496-1.476 1.496c-.82 0-1.496-.676-1.496-1.516 0-.82.677-1.476 1.496-1.476zm-7.255-1.23c-3.792 3.792-3.792 9.94 0 13.733 3.792 3.791 9.94 3.791 13.732 0 3.792-3.792 3.792-9.941 0-13.733-3.792-3.791-9.94-3.791-13.732 0z"/>
                        </g>
                    </g>
                </svg>
                ${__("View Details")}
            </div>
        </div>`).appendTo(this.$main);
    }

    set_hero_titles(){
        this.$hero_wrapper = this.$main.find(".hero-wrapper");
        this.$hero_wrapper.find(".main-heading").html(this.get_main_heading());
        this.$hero_wrapper.find(".sub-heading")
            .removeClass("d-none")
            .text(`Each quote is good for up to seven days, but you’ll need the quote ID number to access it.`);
    }

    get_customer_full_name(){
        let name = '';
        if(this.data.lead){
            name = __("{0} {1}", [this.data.lead.first_name, this.data.lead.last_name]);
        }
        return name;
    }

    get_customer_first_name(){
        let name = "";
        if(this.data.lead){
            name = this.data.lead.first_name;
        }
        return name;
    }

    setup_margin(){
        this.margin_controller.setup_margin();
    }

    get_single_quote_coverage_title(quote){
        return quote && quote.tooltip_placeholder?quote.tooltip_placeholder:'';
    }

    get_single_quote_coverage_tooltip(quote){
        return quote && quote.tooltip_text?quote.tooltip_text: '';
    }

    allow_call(){
        return frappe.boot.website_settings.disable_client_application_call_feature == 0 
                && this.data.opportunity.allow_client_application_call;
    }

    disable_calling(){
        $(".make-call").hide().addClass("d-none");
        $(".nav-mobile-btn-mobile-view").hide().addClass("d-none");
        $(".make-call-slider").hide().addClass("d-none");
    }
}
