/*
    System Quote List
*/
import "./base_quotes";

client_portal.quotes.SystemQuotesListController = class SystemQuotesListController extends client_portal.quotes.BaseQuotesListController{

    set_schedule(){
        return new Promise((resolve, reject)=>{
            if(this.quotes){
                //this.handle_modal_events(res);
                const quotes_with_values = this.quotes.filter((item) => item.total_premium > 0);
                const quotes_without_values = this.quotes.filter((item) => item.total_premium <= 0);
                let sorted_quotes = quotes_with_values.sort((a, b) => a.total_premium > b.total_premium ? 1 : -1);

                sorted_quotes = sorted_quotes.concat(quotes_without_values);
                $.each(sorted_quotes, (idx, quote)=>{

                    try{
                        quote.raw_data = JSON.parse(quote.raw_data);
                    }catch(e){
                        quote.raw_data = {};
                    }
                    quote.idx = idx+1;
                    this.handle_quote_events(this.get_quote_wrapper(quote), quote);
                });
            }else{
                this.set_quotes_unavailable();
            }
            resolve();
            
        });
    }

    get_quote_wrapper(quote){
        let $quote_wrapper;
    
        $quote_wrapper = $(`<div class="insurance-item js-popup-open-btn">
                    <div class="insurance-row">
                        <div>
                            <div class="insurance-icon">
                                <img src="${this.rater.get_company_logo(quote)}" alt="">
                            </div>
                        </div>
                        <div class="insurance-col-title d-flex justify-content-between align-items-center">
                            <div>
                                <div class="insurance-title">${this.rater.get_company_name(quote)}</div>
                            </div>
                            <div class="insurance-price-wrap js-price-tooltip">
                            </div>
                        </div>
                    </div>
                </div>`);
        $quote_wrapper.appendTo(this.$wrapper);
        $quote_wrapper.find(".insurance-quote-id").html(`Quote ID: <span>${this.rater.get_unique_quote_code(quote)}</span>`);
        if(quote && quote.total){
            $quote_wrapper.find(".insurance-price-wrap").html(`${this.rater.get_installment_payment_label(quote)}<span class="font-landing futura-pt-book">/mo</span>`);
        }else{
            //
            $quote_wrapper
            .find(".insurance-price-wrap")
            .addClass("error")
            .html(`<span class="error">...</span><span class="font-landing futura-pt-book">/mo</span>`);
        }
        return $quote_wrapper;
     }
}

/*
    System Quote Modal
*/

client_portal.quotes.SystemQuoteModal = class SystemQuoteModal extends client_portal.quotes.BaseModal{
    make(){
        super.make();
        this.handle_call();
        this.handle_chat();
        this.handle_schedule_call();
        this.handle_close();
    }

    get_wrapper(){
        return $(`<div class="single-quotes single-quotes--full-coverage overflow-auto d-none">
            <div class="basic-coverage-info position-absolute js-basic-coverage" style="display:block;">
                <img class="mt-n1 mr-1" src="/assets/bond_assets/images/icons/info-icon.svg" alt="">
                <img class="mt-n1 mr-1 img-active" src="/assets/bond_assets/images/icons/info-icon-active.svg" alt="">
                <span class="coverage-title"></span>
            </div>
            <div class="popup-close position-absolute">${this.get_close_btn()}</div>
            <div class="single-quotes__header text-center">
                <img class="single-quotes__logo carrier-logo" alt="">
                <p class="single-quotes__id futura-pt-book mb-0"></p>
            </div>
            <div class="single-quotes__body single-quote-schedule d-none"></div>

            <div class="quotes-error mx-auto d-none">
                <div class="quotes-error__img-wrap text-center">
                    <img src="/assets/bond_assets/images/icons/single-quotes-error-icon.svg" alt="">
                </div>
                <div class="quotes-error__desc text-center mx-auto">
                    ${__("Sometimes a carrier is unable to display a quote. Get in touch via phone and we’ll let you know what the quote is.")}
                </div>
            </div>

            <div class="single-quotes__btn-wrap d-flex justify-content-center flex-wrap">
                <button type="button" class="btn single-quotes__btn single-quotes__btn--call text-center js-btn-after-hour-v2">
                    ${__("Schedule Call")}
                </button>
                <button type="button" class="btn js-btn-chat single-quotes__btn single-quotes__btn--chat text-center d-none">
                    ${__("Chat")}
                </button>
                <button type="button" class="btn js-btn-call single-quotes__btn single-quotes__btn--call text-center">
                    ${__("Call Me Now")}
                </button>
            </div>
            <div class="single-quotes__desc futura-pt-book">
                ${__(`If you'd like to purchase a policy now, choose Call Me Now and one of our licensed insurance experts will get in touch. If you don't need immediate assistance, choose Schedule Call and let us know a day and time that works best for you.`)}
            </div>
        </div>`).appendTo(".bg-quotes-gradient-wrapper");
    }
}