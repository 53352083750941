/*
    Third Party Quotes List
*/
import "./base_quotes";
import "./system_quotes";
import "./third_party_quotes";

client_portal.quotes.HybridQuotesListController = class HybridQuotesListController extends client_portal.quotes.BaseQuotesListController{

    set_schedule(){

        return new Promise((resolve, reject)=>{
            if(this.system_quotes){
                //this.handle_modal_events(res);
                const quotes_with_values = this.system_quotes.filter((item) => item.total_premium > 0);
                const quotes_without_values = this.system_quotes.filter((item) => item.total_premium <= 0);
                let sorted_quotes = quotes_with_values.sort((a, b) => a.total_premium > b.total_premium ? 1 : -1);

                sorted_quotes = sorted_quotes.concat(quotes_without_values);
                $.each(sorted_quotes, (idx, quote)=>{

                    try{
                        quote.raw_data = JSON.parse(quote.raw_data);
                    }catch(e){
                        quote.raw_data = {};
                    }
                    quote.idx = idx+1;
                    this.handle_quote_events(this.get_system_quote_wrapper(quote), quote);
                });
            }
            if(this.third_party_quotes && this.third_party_quotes.length){
                $(`<div class="insurance-item insurance-item--hybrid-title">
                    <div class="insurance-row justify-content-center">
                        <p class="insurance-title text-center">The following quotes are outside of our network.</p>
                    </div>
                </div>`).appendTo(this.$wrapper);

                $.each(this.third_party_quotes, (idx, quote)=>{
                    quote.idx = idx+1;
                    this.handle_quote_events(this.get_third_party_quote_wrapper(quote), quote);
                });
            }
            resolve();
        });
    }

    handle_quote_events($quote, quote){
        return $quote.on("click", (e)=>{
            return this.rater.modal_controller.show(quote);
        });
    }

    get_quote_wrapper(quote){
        let $quote_wrapper;
        if(quote.is_third_party){
            $quote_wrapper = this.get_third_party_quote_wrapper(quote);
        }else{
            $quote_wrapper = this.get_system_quote_wrapper(quote);
        }

        return $quote_wrapper;
     }

     get_company_headline(quote){
        return quote.company_headline;
     }

     get_system_quote_wrapper(quote){
        let $quote_wrapper = $(`<div class="insurance-item js-popup-open-btn">
                    <div class="insurance-row">
                        <div>
                            <div class="insurance-icon">
                                <img src="${this.rater.get_company_logo(quote)}" alt="">
                                <img class="hover-icon" src="${this.rater.get_hybrid_hover_over_icon(quote)}" alt="">
                            </div>
                        </div>
                        <div class="insurance-col-title d-flex justify-content-between align-items-center">
                            <div>
                                <div class="insurance-title">${this.rater.get_company_name(quote)}</div>
                                <div class="insurance-quote-id d-none d-sm-block futura-pt-book"></div>
                            </div>
                            <div class="insurance-price-wrap js-price-tooltip">
                            </div>
                        </div>
                    </div>
                </div>`);
        $quote_wrapper.appendTo(this.$wrapper);
        //$quote_wrapper.find(".insurance-quote-id").html(`Quote ID: <span>${this.rater.get_unique_quote_code(quote)}</span>`);
        if(quote && quote.total){
            $quote_wrapper.find(".insurance-price-wrap").html(`${this.rater.get_installment_payment_label(quote)}<span class="font-landing futura-pt-book">/mo</span>`);
        }else{
            //
            $quote_wrapper
            .find(".insurance-price-wrap")
            .addClass("error")
            .html(`<span class="error">...</span><span class="font-landing futura-pt-book">/mo</span>`);
        }
        return $quote_wrapper;
     }

     get_third_party_quote_wrapper(quote){
        let $quote_wrapper = $(`<div class="insurance-item insurance-item--hybrid js-get-quote-open-btn">
                <div class="insurance-row">
                    <div class="insurance-col-img">
                        <div class="insurance-icon">
                                <img src="${this.rater.get_company_logo(quote)}" alt="">
                        </div>
                    </div>
                    <div class="insurance-col-title d-flex justify-content-between align-items-center">
                        <div>
                            <div class="insurance-title d-none d-sm-block">${this.rater.get_company_name(quote)}</div>
                            <div class="insurance-sub-title d-none d-sm-block futura-pt-book">
                                ${this.get_company_headline(quote)}
                            </div>
                        </div>
                        <div>
                            <button class="btn btn-warning btn-lg">${__("Get Quote")}</button>
                        </div>
                    </div>
                </div>
                <div class="insurance-sub-title d-sm-none futura-pt-book">
                    ${this.get_company_headline(quote)}
                </div>
            </div>`).appendTo(this.$wrapper);
        return $quote_wrapper;
     }
}

/*
    Third Party Quote Modal
*/
import "./system_quotes";
import "./third_party_quotes";

client_portal.quotes.HybridQuoteModal = class HybridQuoteModal extends client_portal.quotes.BaseModal{

    make(){
        let args = {
            rater: this.rater,
            data: this.data
        };
        this.system_modal_controller = new client_portal.quotes.SystemQuoteModal(args);
        this.third_party_modal_controller = new client_portal.quotes.ThirdPartyQuoteModal(args);
    }

    show(quote){
        this.quote = quote;
        if(quote && quote.is_third_party){
            this.third_party_modal_controller.show(quote);
        }else if(quote){
            this.system_modal_controller.show(quote);
        }
    }

    hide(){
        if(this.quote && this.quote.is_third_party){
            this.third_party_modal_controller.hide();
        }else if(this.quote){
            this.system_modal_controller.hide();
        }
    }
}