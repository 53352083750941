
/*
    Third Party Rater
*/
import "./base_rater";

client_portal.rater.ThirdPartyRater = class ThirdPartyRater extends client_portal.rater.BaseRater{

    make(){
        this.is_third_party = true;
        if(bond_assets.device.is_mobile_device()){
            this.$parent.find(".video-bg").removeClass("d-none");
            $(".main-heading").html("Your quotes are about to be displayed.");
        }else{
            this.set_background_image();
        }
        this.setup_back_button();
        this.setup_price_tooltip();
        this.setup_call_close_button();
        this.get_quotes().then((res)=>{
            this.data = res.message;
            this.set_hero_titles();
            this.setup_controllers();
            this.validate_and_show_view();
            if(!this.allow_call()){
                this.disable_calling();
            }
        });
    }

    set_hero_titles(){
        if(args.data && args.data.quotes){
            this.quotes = args.data.quotes;
        }
        if(this.data && !this.data.quotes.length){

        }
    }

    setup_controllers(){
        let args = {
            rater: this,
            data: this.data
        }
        this.margin_controller = new client_portal.ui.MarginController(args);
        this.quote_list_controller = new client_portal.quotes.ThirdPartyQuotesListController(args);
        this.call_controller = new client_portal.call.CallController(args);
        this.modal_controller = new client_portal.quotes.ThirdPartyQuoteModal(args);
        this.after_hour_controller = new client_portal.calendar.AfterHourController(args);
    }

    set_hero_titles(){
        this.$hero_wrapper = this.$main.find(".hero-wrapper");
        this.$hero_wrapper.find(".main-heading").html(this.get_main_heading());
        let desc = 'Even though we’re not able to service your area, we’re still dedicated to helping you find the lowest rate.';
        if(!this.has_quotes()){
            desc = 'Unfortunately, no carriers submitted quotes based on the information you provided. We suggest you try contacting a carrier directly.';
        }
        this.$hero_wrapper.find(".sub-heading")
            .removeClass("d-none")
            .text(desc);
    }

    get_personal_auto_heading(){
        let sub_title = "Compare and Save";
        if(!this.has_quotes()){
            sub_title = "No quotes available";
        }
        let title = __("<span class='bold'>{0} {1}</span>", [this.data.lead.first_name, this.data.lead.last_name])
        title = __("{0} | {1}", [title, sub_title]);
        return title;
    }

    has_quotes(){
        let flag = true;
        if(this.data && !this.data.quotes.length){
            flag = false;
        }else if(!this.data){
            flag = false;
        }
        return flag;
    }

}