/*
    Hybrid Rater
*/

import "./base_rater";

client_portal.rater.HybridRater = class HybridRater extends  client_portal.rater.BaseRater{

    constructor(){
        super();
    }

    make(){
        this.is_hybrid = true;
        this.show_confirmation_message();
        if(bond_assets.device.is_mobile_device()){
            this.$parent.find(".video-bg").removeClass("d-none");
            $(".main-heading").html("Your quotes are about to be displayed.");
        }else{
            this.set_background_image();
        }
        this.setup_back_button();
        this.setup_price_tooltip();
        this.setup_call_close_button();
        this.get_quotes().then((res)=>{
            this.data = res.message;
            this.set_hero_titles();
            this.setup_controllers();
            this.validate_and_show_view();
            if(!this.allow_call() || !company_schedule.utils.is_available()){
                this.disable_calling();
            }
        });
    }

    setup_controllers(){
        let args = {
            rater: this,
            data: this.data
        }
        this.margin_controller = new client_portal.ui.MarginController(args);
        this.quote_list_controller = new client_portal.quotes.HybridQuotesListController(args);
        this.call_controller = new client_portal.call.CallController(args);
        this.modal_controller = new client_portal.quotes.HybridQuoteModal(args);
        this.after_hour_controller = new client_portal.calendar.AfterHourController(args);
    }

}
